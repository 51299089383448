.widget {
  background-color: whitesmoke;
  border: 1px solid #eee;
  box-shadow: 0 3px 3px #ccc;
  display: inline-block;
  font: 12px Arial, sans-serif;
  height: 200px;
  margin: 5px;
  padding: 15px;
  width: 200px;
}
.widget-large {
  background-color: whitesmoke;
  border: 1px solid #eee;
  box-shadow: 0 3px 3px #ccc;
  display: inline-block;
  font: 12px Arial, sans-serif;
  height: 300px;
  margin: 5px;
  padding: 15px;
  width: 300px;
}
.home{
  text-align: center;
  border: 3px solid green;
  padding: 50px 0;
  margin-top: 100px;
}
@import url('https://fonts.googleapis.com/css?family=Roboto');
/* Global Styles */
:root {
  --nav-primary-color: rgb(3, 87, 126);
  --nav-text-color:rgb(255, 255, 255);
  --nav-a-hov-color:Blue;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}
a {
  color: var(--nav-primary-color);
  text-decoration: none;
}
a:hover {
  color: #666;
}
ul {
  list-style: none;
}
img {
  width: 100%;
}
/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}
/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.bg-primary{
  background: var(--nav-primary-color);
  color: var(--nav-text-color);
}
/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  margin-bottom: 3rem;
}
.navbar ul {
  display: flex;
  list-style: none;
}
.navbar a {
  color: var(--nav-text-color);
  padding: 0.45rem;
  margin: 0 0.25rem;
}
.navbar a:hover {
  color: var(--nav-a-hov-color);
}
/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }
.grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }
/* Text Styles */
  .x-large {
    font-size: 3rem;
  }
.large {
    font-size: 2rem;
  }
.lead {
    font-size: 1rem;
  }
/* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }
.navbar ul {
    text-align: center;
    justify-content: center;
  }
}